import { Map as imMap, fromJS, is } from 'immutable';

import logger from './utils/logger';
import { UPDATE_TOC, RECEIVE_DOC, SET_NETWORK_STATE, LOAD_ACCOUNT, SET_TOC_COLLAPSED } from './actionTypes';
import { swaggerEntries, sortToc } from './utils/toc';
import { getLocaleToc, getFilePathMap } from './utils/selectors';

export const reducers = {
  [UPDATE_TOC]: function updateToc(state, { toc }) {
    toc = fromJS(toc);
    let updatedToc = getLocaleToc(toc);

    if (!is(state.get('toc'), updatedToc)) {
      updatedToc = sortToc(updatedToc);

      state = state.set('rawToc', toc);
      // TODO: put these into a memoized prop provider
      state = state.set('toc', updatedToc);
      state = state.set('swaggerEntries', swaggerEntries(updatedToc));
      state = state.set('filePathMap', getFilePathMap(updatedToc));
    }

    return state;
  },
  [SET_TOC_COLLAPSED]: function setTocCollapsed(state, { collapsed }) {
    return state.set('tocCollapsed', collapsed);
  },
  [RECEIVE_DOC]: function receiveDoc(state, { doc, hash }) {
    return state.setIn(['docs', hash], doc);
  },
  [SET_NETWORK_STATE]: function setNetworkState(state, { id, state: networkState }) {
    // NOTE: networkState is expected to be from the constants or a HTTP status code
    return state.setIn(['network', id], networkState);
  },

  [LOAD_ACCOUNT]: function loadAccountName(state, { name, id }) {
    state = state.set('accountName', name);
    state = state.set('accountId', id);
    return state;
  }
};

export default function reduce(state=imMap(), action) {
  const type = action.type;
  const reducer = reducers[type];
  if (type.startsWith('@@redux')) {
    return state;
  }
  if (!reducer) {
    logger.warn(`"${type}" is not a valid reducer type`);
    return state;
  }
  return reducer(state, action);
}