import { DOC, DIR, ENTRY, FALLBACK_LANGUAGE } from '@au/dev-docs/constants';
import {  CONSOLE_LINK, HELP_LINK, TMC_METRICS_LINK, STATUS_PAGE_LINK } from '@au/core/lib/constants';

// TODO: remove tutorial and reference types
export const TUT_TYPE = 'tut';
export const TUT_DIR = 'tutorial'; // this should maybe be passed via toc.json
export const REF_TYPE = 'ref';
export const REF_DIR = 'reference'; // this should maybe be passed via toc.json


export const LOCAL_DOCS = process.env.REACT_APP_USE_LOCAL_DOCS === 'true';
export const LOCAL_DOCS_FOLDER = process.env.REACT_APP_LOCAL_DOCS_FOLDER;
export const MARKDOWN_INJECTED = 'Markdown Injected';
export const MARKDOWN_CLEARED = 'Markdown Cleared';
export const NOOP = () => {};
export const EMAIL_REGEX = /^(\w.+@){0,1}((?:[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]{0,61}\.)+[a-zA-Z]{2,})$/;

// V2
export const {
  REDIRECT_KEY: REDIRECT_DOC_KEY
} = DOC.METADATA;
export const {
  CHILDREN_KEY: SUB_DOCS_TOC_KEY,
  DESCRIPTION_KEY,
  INDICATOR_KEY,
  COLOR_KEY,
  STYLE_KEY: ZONE_STYLE_KEY,
  SPLASH_KEY: ZONE_SPLASH_KEY,
  SWAGGER_URL_KEY,
  SWAGGER_TITLE_KEY,
} = DIR.METADATA;
export const {
  SORT_KEY: META_SORT_KEY,
  TITLE_KEY: TITLE_DOC_KEY,
  HASH_KEY: HASH_DOC_KEY,
  PERMALINK_KEY: PERMALINKS_DOC_KEY,
  LAST_MODIFIED_KEY: DOC_LAST_MODIFIED_KEY,
  PATH_KEY: PATH_TOC_KEY
} = ENTRY.METADATA;

export const DOC_CONTENT_KEY = '_doc';
export const DOC_APP_PATH_KEY = '_appPath';
export const DOC_TOC_PATH_KEY = '_tocPath';
export const IS_CHOSEN_LANG = 'isChosenLang';
export const FILE_LINK_APP = 'appFilePath';
export const FALLBACK_LANG = FALLBACK_LANGUAGE;
export const TOC_MAPPINGS_KEY = '_tocMappings';
export const SWAGGER_PATH = '/swagger-ui';
export const DEV_PREVIEW_PATH = '/dev-preview';

export const RELEASE_NOTES_SUBSCRIPTION_LINK = 'https://autonomic-6374686.hs-sites.com/en/tmc-email-subscriptions';

export const LIST_PATH = 'list';

export const DOC_DATA_ID = 'docContainer';

export const DESKTOP_SCREENWIDTH = ['desktop', 'desktopLarge', 'desktopExtraLarge'];

export const MIN_CHARACTERS_TO_SEARCH = 3;

// Network states
export const NETWORK_STATES = Object.freeze({
  initial: null,
  fetching: 'FETCHING',
  done: 'DONE',
  unreachable: 'UNREACHABLE'
  // or a HTTP status code
});

export const TOC_STATES = Object.freeze({
  initial: null,
  collapsed: true,
  expanded: false,
});

export const UNSET_SCHEMA_REFS = Object.freeze({
  'command-service': {
    'components.schemas': [
      'BulkCommandStatus',
      'MetricsResponse',
      'PaginatedResponseCommandStatus',
      'StateTransitionData'
    ]
  },
  'telemetry-service': {
    'components.schemas': [
      'EventConditions',
      'EventsResponse',
      'MetricsResponse',
      'StatesResponse',
      'StateTrigger'
    ]
  },
  'vehicle-service': {
    'definitions': [
      'Events',
      'Metrics'
    ]
  }
});

//This is for the ordered set of links to ensure that link order stays the same.
export const tmcLinkTypes = Object.freeze([
  CONSOLE_LINK,
  HELP_LINK,
  TMC_METRICS_LINK,
  STATUS_PAGE_LINK
]);

export const BASE_DOMAIN_NA   = 'autonomic.ai';
export const BASE_DOMAIN_CNP0 = 'p0.tmc79.cn';
export const BASE_DOMAIN_CNP1 = 'p1.tmc79.cn';

export const ENV_CNP0 = 'cnp0';
export const ENV_CNP1 = 'cnp1';