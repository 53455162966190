// Polyfills (because IE 11)
// Adds about 38.85 KB to build
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

// React
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Config as AWSconfig, CognitoIdentityCredentials } from 'aws-sdk';

// NOTE main.css should be imported before Root and other components
import './css/main.scss';

import { updateToc, loadAccount } from './actionCreators';
import shared from './shared';
import store from './store';
import { CI } from './utils/ci';
import { unregister } from './registerServiceWorker';
import Root from './Root';
import IntlProvider from './containers/IntlProvider';
import { NOOP } from './constants';

import AuAnalytics, { buildUserInfo } from '@au/core/lib/utils/AuAnalytics';
import authenticate from '@au/core/lib/utils/auth';
import { fetchJson } from '@au/core/lib/network/fetch';
import { clearStoredDomain, getUserDomain, writeDomainCookie } from '@au/core/lib/utils/domain';
import { AuDomainBootstrap } from '@au/core/lib/components/elements';
import TMC from '@autonomic/browser-sdk';


const rootEl = createRoot(document.getElementById('root'));

function setApplicationSettings(settings) {
  // Base domain, used for creation of the API URL
  const apiDomain = 'api.' + settings.baseDomain;
  // API URL
  const apiUrl = 'https://' + apiDomain;

  // Update shared config object
  shared.config = Object.assign({}, settings, {
    apiDomain,
    apiUrl
  });
}


function initializeAWS(appConfig, authClient) {
  // when developing locally do not initialize AWS because most likely we are
  // using a dev client which will be reject because of the wrong audience
  if (process.env.NODE_ENV === 'development') {
    console.warn('Disabling AWS auth and features in development environment'); // eslint-disable-line no-console
    return Promise.resolve();
  }

  // Cloud Search URL (goes through API Gateway)
  const contentSet = appConfig.s3Bucket.split('-').pop();
  shared.cloudSearchUrl = `https://${appConfig.cloudSearchDomain}/docs/${contentSet}`;

  const identityService = authClient.accessTokenPayload.iss.split('https://')[1];

  const addToken = () =>
    shared.awsConfigObj.credentials.params.Logins[identityService] = authClient.accessToken;

  const awsConfigObj = new AWSconfig({
    region: appConfig.region,
    credentials: new CognitoIdentityCredentials({
      IdentityPoolId: appConfig.identityPoolId,
      Logins: {
        [identityService]: authClient.accessToken
      }
    }, { region: appConfig.region })
  });
  shared.awsConfigObj = awsConfigObj;
  authClient.registerAuthRefresh(addToken);

  // refresh token to catch errors and misconfigurations early
  return new Promise((res, rej) => {
    awsConfigObj.credentials.refresh(err => {
      if (!err) {
        res(awsConfigObj);
        return;
      }
      let description = err.message || err.toString();
      if (err.originalError && err.originalError.message) {
        description = err.originalError.message;
      }
      AuAnalytics.trackException(description, true);
      rej(description);
    });
  });
}


function initializeApp(appConfig) {
  if (CI) {
    window.onerror = mesg => {
      console.error(mesg); // eslint-disable-line no-console
    };
  }

  setApplicationSettings(appConfig);

  store.dispatch(updateToc());

  setInterval(() => store.dispatch(updateToc()), 30000);

  rootEl.render(<Root />);

}

function buildAuthConfig(appConfig) {
  return {
    clientId: appConfig.clientId,
    idpHint: appConfig.idpHint,
    baseDomain: appConfig.baseDomain
  };
}


function initialize(appConfig) {
  const noAuth = process.env.REACT_APP_NO_AUTH === 'true';

  if (noAuth) {
    initializeApp(appConfig);
    //intentionally not initializing analytics in noauth case.
  }
  else {
    const config = buildAuthConfig(appConfig);
    authenticate({ TMC, config, shared }).then(async client => {
      const userInfo = buildUserInfo(client);

      const analyticsConfig = {
        ga: {
          trackingId: appConfig.gaTrackingId,
          options: {debug: false}
        }
      };
      if (appConfig.dd) {
        analyticsConfig.dd = {
          config: {
            token: appConfig.dd.token,
            appId: appConfig.dd.appId
          },
          userInfo
        };
      }
      if (appConfig.hubspotAccountId) {
        analyticsConfig.hs = {
          config: {
            accountId: appConfig.hubspotAccountId
          },
          userInfo
        };
      }
      AuAnalytics.initialize(analyticsConfig);

      store.dispatch(loadAccount(client.accountId));
      await initializeAWS(appConfig, client).catch(NOOP); // TODO: handle cognito issue better
      initializeApp(appConfig);
    });
  }
}


function main(domain) {
  //GET USER DOMAIN FROM LOCAL STORAGE
  domain = domain || getUserDomain();

  writeDomainCookie(domain);

  //FETCH APP CONFIG BASED ON THE DOMAIN
  //IF EITHER MISSING, DISPLAY DOMAIN BOOTSTRAP COMPONENT
  if (!domain){
    rootEl.render(<IntlProvider><AuDomainBootstrap callback={main} /></IntlProvider>)
  }
  else {
    //ELSE FETCH portal application json FILE FROM THE SERVER AND PROCEED
    fetchJson(`/config/${domain}/application.json`)
      .then(
        appConfig => initialize({...appConfig, emailDomain: domain}),
        () => { clearStoredDomain(); main(); }
      );
  }

  unregister(); // no service worker for dev-portal since our bundle.js is too big
}


main();