import coreLocales from '@au/core/lib/locales';

const defaultEnglish = {
  'au': 'Hello, {name}',
  'au.noop': ' ',
  'au.env.na': 'NA',
  'au.env.cnp0': 'CNP0',
  'au.env.cnp1': 'CNP1',
  'au.unauthorized': 'Unauthorized',
  'au.tmcLinks': "TMC Links",
  'au.tmcMetrics': "TMC Metrics",
  'au.feedback': "Feedback",
  'au.helpCenter': "Help Center",
  'au.console': "Console",
  'au.loadingText': 'Updating...',
  'au.downloadSwagger': 'Download Swagger UI',
  'au.search': 'Search',
  'au.service': 'Service',
  'au.services.BQS.name': 'Behavior Query Service',
  'au.services.DEPLOYMENT.name': 'Deployment Service',
  'au.services.DEPLOYMENTV2.name': 'Deployment Service V2',
  'au.services.DEPLOYMENTV3.name': 'Deployment Service V3',
  'au.services.FEED.name': 'Feed Admin Service',
  'au.services.GEOFENCE.name': 'Geofence Service',
  'au.services.GROUP.name': 'Group Service',
  'au.services.MQS.name': 'Metrics Query Service',
  'au.services.OEM.name': 'OEM Command Service',
  'au.services.RESOURCES.name': 'Resources Service',
  'au.services.RESOURCESV2.name': 'Resources Service V2',
  'au.services.RIDEHAILING.name': 'Ride-Hailing Service',
  'au.services.RIDEHAILINGV2BETA.name': 'Ride-Hailing Service V2 Beta',
  'au.services.VEHICLE.name': 'Vehicle Service',
  'au.services.VSS.name': 'Vehicle State Sreaming Service',
  'au.home.title': 'Developer',
  'au.swagger.failedToLoad': 'Failed to load',
  'au.swagger.setCredentials': 'Use My Client',
  'au.swagger.clearCredentials': 'Clear Client Credentials',
  'au.swagger.seeDefinition': 'See {model} definition',
  'au.navigation.link.referenceDocs': 'Reference Docs',
  'au.navigation.link.tutorials': 'Tutorials',
  'au.navigation.link.swagger': 'Swagger UI',
  'au.navigation.link.devPreview': 'MD Previewer',
  'au.serviceStatus': 'Service Status',
  'au.docs.view': 'View',
  'au.account': 'Account',
  'au.logOut': 'Log Out',
  'au.login': 'Login',
  'au.clientId': 'Client ID',
  'au.clientSecret': 'Client Secret',
  'au.clientId.required': 'Client ID is required.',
  'au.loginWithConfidentialClient': 'Login with Confidential Client',
  'au.doc.lastModified': 'Last modified',
  'au.doc.lastModifiedFormat': 'MMM D YYYY h:mm a zz',
  'au.doc.downloadAs': 'Download as',
  'au.doc.downloadAs.markdown': 'Markdown',
  'au.doc.swagger': 'Swagger',
  'au.subscribe': 'Subscribe to TMC News',
  'au.subscribe.subheader': 'Receive an email notification whenever TMC service updates',
  'au.subscribe.confirmation': 'Subscribe Via Email',
  'au.subscribe.message': 'Autonomic is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. By clicking below, you agree that we may proceed your information in accordance with these terms.',
  'au.subscribe.accept.communication': 'I accept to receive communication from Autonomic',
  'au.subscribe.success': 'Your subscription was successfully submitted!',
  'au.subscribe.success.message': 'Thank you for the subscription! Now you will receive an email notification whenever TMC service updates.',
  'au.subscribe.success.signoff': '- Autonomic team -',
  'au.notFound.pageNotFound': 'Page Not Found',
  'au.notFound.couldNotFindPage': 'Sorry, we could not find the page you requested.',
  'au.notFound.goBack': 'Go Back',
  'au.markdown.copy': 'Copy',
  'au.nav.menu': 'Menu',
  'au.nav.close': 'Close',
  'au.filedrop.msg': 'Drop a Markdown file to preview',
  'au.filedrop.caveat': 'Images Not Supported!!!',
  'au.ie11.title': 'Update your browser for the best experience',
  'au.ie11.body': 'Some features might not work correctly with the browser you’re using. To get the most of our services, we recommend that you update to the current version of one of these browsers:',
  'au.ie11.microsoftEdge': 'Microsoft Edge',
  'au.ie11.mozillaFirefox': 'Mozilla Firefox',
  'au.ie11.googleChrome': 'Google Chrome',
  'au.ie11.safari': 'Safari',
};

const defaultMandarin= {
  'au': '您好, {name}',
  'au.loadingText': '更新中...',
  'au.home.title': 'TMC 开发者门户',
  'au.home.subTitle': '欢迎来到车联云平台! 浏览我们的开发者文档，包括 API 参考、教程和代码示例。',
  'au.swagger.failedToLoad': '加载失败',
  'au.navigation.link.referenceDocs': '参考文档',
  'au.navigation.link.tutorials': '教程',
  'au.navigation.link.devPreview': 'Markdown 预览工具',
  'au.docs.view': '查看',
  'au.account': '账号',
  'au.logOut': '登出',
  'au.doc.lastModified': '最近更新日期:',
  'au.doc.lastModifiedFormat': 'MMM D YYYY h:mm a zz',
  'au.doc.downloadAs': '下载当前文档为',
  'au.notFound.pageNotFound': '页面无法找到',
  'au.notFound.couldNotFindPage': '抱歉，无法找到您请求的页面。',
  'au.notFound.goBack': '返回',
  'au.nav.menu': '菜单',
  'au.nav.close': '关闭',
  'au.filedrop.msg': '拖放 Markdown 文件进行预览',
  'au.filedrop.caveat': '图片不支持！'
};

export default {
  en: Object.assign({}, coreLocales.en, defaultEnglish),
  zh: Object.assign({}, defaultEnglish, (coreLocales.zh || {}), defaultMandarin)
};
